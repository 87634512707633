import React from "react"
import Currency from "../currency"
import Img, { FixedObject } from "gatsby-image"
import { Card, CardHeadData } from "../cards"
import { UnpackedItem } from "../../../lib/types"

export const ItemRow = ({
  item,
  image,
}: {
  item: UnpackedItem
  image: FixedObject
}): JSX.Element => (
  <tr>
    <td className="cell-collapse">
      {image && <Img className="shadow-md " fixed={image} />}
    </td>
    <td>{item.name}</td>
    <td className="text-center">{item.quantity}</td>
    <td className="text-right">
      <Currency amount={item.price} />
    </td>
    <td className="text-right">
      <Currency amount={item.price.mult(item.quantity)} />
    </td>
  </tr>
)

export const CardRow = ({
  item,
  image,
}: {
  item: UnpackedItem
  image: FixedObject
}): JSX.Element => (
  <Card className="my-1">
    {image && (
      <Img className="shadow-md w-full flex-grow w-full" fixed={image} />
    )}
    <CardHeadData name="Title">{item.name}</CardHeadData>
    <CardHeadData name="Quantity">{item.quantity}</CardHeadData>
    <CardHeadData name="Price(each)">
      <Currency amount={item.price} />
    </CardHeadData>
    <CardHeadData name="Price(total)">
      <Currency amount={item.price.mult(item.quantity)} />
    </CardHeadData>
  </Card>
)
