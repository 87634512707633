import { useState } from "react"

interface State {
  error: boolean
  message: string
}

type SetState = React.Dispatch<React.SetStateAction<State>>

const useError = () => {
  const [state, setState]: [State, SetState] = useState<State>({
    error: false,
    message: "",
  })

  const { error, message } = state

  const setError = (message: string) =>
    setState({ error: true, message: message })

  const clearError = () => setState({ error: false, message: "" })

  return { error, message, setError, clearError }
}

export default useError
