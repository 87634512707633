import React from "react"
import Currency from "../currency"
import { Cents } from "../../../lib/currency"
import { Card, Cards, CardHeadData } from "../cards"
import { ItemRow, CardRow } from "./row"
import ItemRows from "./rows"
import { SlugToImageMap } from "../../hooks/use-all-item-images"
import { ShippingResponse } from "../../../lib/shipping"
import { UnpackedItem } from "../../../lib/types"

interface Props {
  checkoutState: {
    items: UnpackedItem[]
    shipping: ShippingResponse | null
    shippingTotal: Cents | null
    slugToImage: SlugToImageMap
    subTotal: Cents
    tax: Cents | null
    total: Cents | null
  }
  className?: string
}

const OrderSummary = ({
  checkoutState: {
    items,
    shipping,
    shippingTotal,
    slugToImage,
    subTotal,
    tax,
    total,
  },
  className,
}: Props) => {
  return (
    <div className={className}>
      <h2>Order Summary</h2>
      {shipping && shipping.error && (
        <div className="font-bold text-red-500">
          There was an error fetching your shipping estimate.
        </div>
      )}
      <table className="hidden md:table w-full mb-2 border-collapse checkoutTable">
        <thead>
          <tr className="border-0 border-b border-solid border-b-gray-800 border-collapse">
            <th className="p-1" />
            <th className="p-1">Title</th>
            <th className="p-1">Quantity</th>
            <th className="p-1 text-right">Price(each)</th>
            <th className="p-1 text-right">Price(total)</th>
          </tr>
        </thead>
        <tbody>
          {ItemRows(ItemRow, {
            slugToImage,
            items: items,
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} />
            <td className="text-right">Sub Total</td>
            <td className="text-right">
              <Currency amount={subTotal} />
            </td>
          </tr>
          <tr>
            <td colSpan={3} />
            <td className="text-right">Taxes</td>
            <td className="text-right">
              <Currency amount={tax} empty="Billing Address Needed" />
            </td>
          </tr>
          <tr>
            <td colSpan={3} />
            <td className="text-right">Shipping</td>
            <td className="text-right">
              <Currency
                amount={shippingTotal}
                empty="Shipping Address Needed"
              />
            </td>
          </tr>
          <tr>
            <td colSpan={3} />
            <td className="font-bold text-right">Total</td>
            <td className="font-bold text-right">
              <Currency
                amount={total}
                empty="Shipping & Billing Address Needed"
              />
            </td>
          </tr>
        </tfoot>
      </table>
      <Cards className="flex flex-col md:hidden">
        {ItemRows(CardRow, { slugToImage, items: items })}
        <Card>
          <CardHeadData name="Sub Total">
            <Currency amount={subTotal} />
          </CardHeadData>
          <CardHeadData name="Taxes">
            <Currency amount={tax} empty="Billing Address Needed" />
          </CardHeadData>
          <CardHeadData name="Shipping">
            <Currency amount={shippingTotal} empty="Shipping Address Needed" />
          </CardHeadData>
          <CardHeadData name="Total">
            <Currency
              amount={total}
              empty="Shipping & Billing Address Needed"
            />
          </CardHeadData>
        </Card>
      </Cards>
    </div>
  )
}

export default OrderSummary
