import React from "react"
import { FixedObject } from "gatsby-image"
import { SlugToImageMap } from "../../hooks/use-all-item-images"
import { UnpackedItem } from "../../../lib/types"

type Component = React.FunctionComponent<{
  key: number
  item: UnpackedItem
  image: FixedObject
}>

const ItemRows = (
  component: Component,
  {
    slugToImage,
    items,
  }: {
    slugToImage: SlugToImageMap
    items: UnpackedItem[]
  }
) =>
  Object.values(items).map((item: UnpackedItem, i: number) =>
    React.createElement(component, {
      key: i,
      item: item,
      image: slugToImage[`${item.category}-${item.name}`],
    })
  )

export default ItemRows
