import fetch from "isomorphic-fetch"
import { Charge } from "./schemas/charge"

interface ChargeResponseError {
  error: true
  message: string
}

interface ChargeResponseSuccess {
  error: false
  charge: Record<string, unknown>
}

type ChargeResponse = ChargeResponseSuccess | ChargeResponseError

export const charge = async (
  data: Charge,
  baseUrl: string
): Promise<ChargeResponse> => {
  const url = new URL("/.netlify/functions/charge", baseUrl)

  const rawResponse = await fetch(url.toString(), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  if (rawResponse.status >= 200 && rawResponse.status < 300) {
    const content = await rawResponse.json()
    return { error: false, charge: content }
  } else {
    Sentry.setContext("rawResponse", {
      status: rawResponse.status,
      body: await rawResponse.text(),
    })
    Sentry.captureMessage("Charge Failed")
    return {
      error: true,
      message:
        "There is a problem processing your order at this time. Please try again later or contact us directly.",
    }
  }
}
