import React from "react"
import classnames from "classnames"

export const DL = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => <dl className={classnames("flex", "flex-wrap", className)}>{children}</dl>

export const DT = ({
  name,
  className,
}: {
  name: string
  className?: string
}) => (
  <dt
    className={classnames(
      "w-2/6",
      "border-box",
      "text-right",
      "font-bold",
      className
    )}
  >
    {name}:
  </dt>
)

export const DD = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <dd className={classnames("w-4/6", "border-box", "m-0", "pl-2", className)}>
    {children}
  </dd>
)

export const DTD = ({
  name,
  titleClassName,
  dataClassName,
  children,
}: {
  name: string
  titleClassName?: string
  dataClassName?: string
  children: React.ReactNode
}) => (
  <>
    <DT className={titleClassName} name={name} />
    <DD className={dataClassName}>{children}</DD>
  </>
)
