import React from "react"

const SpinOrText = ({ text, spin }: { text: string; spin: boolean }) => {
  if (!spin) return <>{text}</>

  return (
    <>
      <span
        className="spinner-border spinner-border-sm mx-2"
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </>
  )
}

export default SpinOrText
