import React, { useState, Fragment } from "react"
import SSLFooter from "../components/ssl_footer"
import useError from "../hooks/use-error"
import { Link, navigate } from "gatsby"
import OrderSummary from "../components/checkout/order-summary"
import { DL, DTD } from "../components/dl"
import { charge } from "../../lib/charge"
import useCheckout from "../hooks/use-checkout"
import { ShippingSchema } from "../../lib/schemas/shipping"
import { Cents } from "../../lib/currency"
import { UnpackedItem } from "../../lib/types"
import SpinOrText from "../components/spin_or_text"

const handleSubmit =
  (
    checkoutState: {
      items: UnpackedItem[]
      total: Cents | null
      addresses: ShippingSchema
      clearCart: () => void
    },
    token: string,
    setSubmitting: (arg0: boolean) => void,
    setError: (arg0: string) => void,
    clearError: () => void
  ) =>
  async (e: React.MouseEvent) => {
    const { items, total, addresses, clearCart } = checkoutState
    e.preventDefault
    setSubmitting(true)
    clearError()
    if (!total) throw "Total Missing"

    if (!("firstName" in addresses.billingAddress))
      throw "Billing Address Missing"

    if (!token) throw "Token Missing"

    const data = {
      items: items.map((item) => {
        return {
          quantity: item.quantity,
          signed: item.signed,
          sku: item.sku,
        }
      }),
      shippingAddress: addresses.shippingAddress,
      billingAddress: addresses.billingAddress,
      email: addresses.email,
      token: token,
      total: total.amount(),
    }

    const chargeResponse = await charge(data, window.location.origin)

    setSubmitting(false)

    if (chargeResponse.error) {
      setError(chargeResponse.message)
      return
    }

    clearCart()
    navigate("/basket/thank-you")
  }

interface ConfirmationProps {
  location: {
    state?: {
      token?: string
    }
  }
}

const BackToBasket = ({ isSubmitting }: { isSubmitting: boolean }) => (
  <Link
    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded inline-block ml-1 md:ml-2"
    to={isSubmitting ? "/checkout" : "/basket"}
  >
    Back to Basket
  </Link>
)

const Confirmation = ({ location: { state } }: ConfirmationProps) => {
  const token = state && state.token

  const checkoutState = useCheckout()
  const {
    addresses: { shippingAddress, billingAddress },
  } = checkoutState
  const [isSubmitting, setSubmitting] = useState(false)
  const { error, message, setError, clearError } = useError()

  if (!token)
    return (
      <Fragment>
        Checkout Temporarily Unavailable
        <BackToBasket isSubmitting={isSubmitting} />
      </Fragment>
    )

  const sFirstName = shippingAddress.firstName
  const sMiddleInitial = shippingAddress.middleInitial
  const sLastName = shippingAddress.lastName
  const sAddress1 = shippingAddress.address1
  const sAddress2 = shippingAddress.address2
  const sCity = shippingAddress.city
  const sState = shippingAddress.state
  const sZip = shippingAddress.zip
  const sPhone = shippingAddress.phone

  const bFirstName = "firstName" in billingAddress && billingAddress.firstName
  const bMiddleInitial =
    "middleInitial" in billingAddress && billingAddress.middleInitial
  const bLastName = "lastName" in billingAddress && billingAddress.lastName
  const bAddress1 = "address1" in billingAddress && billingAddress.address1
  const bAddress2 = "address2" in billingAddress && billingAddress.address2
  const bCity = "city" in billingAddress && billingAddress.city
  const bState = "state" in billingAddress && billingAddress.state
  const bZip = "zip" in billingAddress && billingAddress.zip
  const bPhone = "phone" in billingAddress && billingAddress.phone

  return (
    <div className="flex flex-wrap">
      {error && <div className="w-full">{message}</div>}
      <div className="w-full md:w-1/2">
        <h2>Shipping Address</h2>
        <DL>
          <DTD name="Name">
            {[sFirstName, sMiddleInitial, sLastName].join(" ")}
          </DTD>
          <DTD name="Address">
            <div>{sAddress1}</div>
            <div>{sAddress2}</div>
            <div>
              {sCity}, {sState} {sZip}
            </div>
          </DTD>
          <DTD name="Phone">{sPhone}</DTD>
        </DL>
      </div>
      <div className="w-full md:w-1/2">
        <h2>Billing Address</h2>
        <DL>
          <DTD name="Name">
            {[bFirstName, bMiddleInitial, bLastName].join(" ")}
          </DTD>
          <DTD name="Address">
            <div>{bAddress1}</div>
            <div>{bAddress2}</div>
            <div>
              {bCity}, {bState} {bZip}
            </div>
          </DTD>
          <DTD name="Phone">{bPhone}</DTD>
        </DL>
      </div>

      <OrderSummary className="w-full" checkoutState={checkoutState} />
      <div className="w-full">
        <button
          type="submit"
          disabled={isSubmitting}
          className="bg-bm-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-1 md:mr-2"
          onClick={handleSubmit(
            checkoutState,
            token,
            setSubmitting,
            setError,
            clearError
          )}
        >
          <SpinOrText text="Submit Order" spin={isSubmitting} />
        </button>
        <BackToBasket isSubmitting={isSubmitting} />
      </div>
      <SSLFooter className="w-full" />
    </div>
  )
}

export default Confirmation
